/* eslint-disable max-lines */
import { InitPayPal } from 'solid-payment/src/interfaces/payment';
import { InitConfig } from '@solidgate/client-sdk-loader';

import * as actionTypes from './actionTypes';

import { DEFAULT_MODAL_OPEN_STATUS_VALUES } from 'constants/payment/modals';

import { IPaymentFailToReteno, PaymentData } from 'types/payments/paymentApiInterfaces';

import { ModalOpenStatuses } from 'interfaces/Store/paymentStore';
import { Init } from 'interfaces/Payments/paymentSystemsInit';
import { IncomingPayloadForValidate, PaymentDataAfterSuccessfulValidate } from 'interfaces/Payments/payments';
import { Currency, IInitOneClickPayment, IReplaceSubscription, Product, UpsellProduct } from '../sagas/user/interfaces';

export const validatePayment = (payload: IncomingPayloadForValidate) => {
    return {
        type: actionTypes.VALIDATE_PAYMENT,
        payload,
    } as const;
};

export const setPaymentData = (data: PaymentData) => {
    return {
        type: actionTypes.SET_PAYMENT_DATA,
        payload: data,
    } as const;
};

export const setLoadingStatus = (status: boolean) => {
    return {
        type: actionTypes.SET_LOADING_STATUS,
        payload: status,
    } as const;
};

export const setCurrentProduct = (product: Product) => {
    return {
        type: actionTypes.SET_CURRENT_PRODUCT,
        payload: product,
    } as const;
};

export const setUpgradedProduct = (product: Product) => {
    return {
        type: actionTypes.SET_UPGRADED_PRODUCT,
        payload: product,
    } as const;
};

export const init = (data: Init) => {
    return {
        type: actionTypes.INIT_PAYMENT,
        payload: data,
    } as const;
};

export const initPayPal = (data: InitPayPal) => {
    return {
        type: actionTypes.INIT_PAY_PAL,
        payload: data,
    } as const;
};

export const initPaymentGenerator = (data: Init) => {
    return {
        type: actionTypes.INIT_PAYMENT_GENERATOR,
        payload: data,
    };
};

export const setMerchantData = (data: InitConfig['merchantData']) => {
    return {
        type: actionTypes.SET_MERCHANT_DATA,
        payload: data,
    };
};

export const initOneClickPayment = (data: IInitOneClickPayment) => {
    return {
        type: actionTypes.INIT_ONE_CLICK_PAYMENT,
        payload: data,
    } as const;
};

export const setUpsellProductId = (upsellId: string) => {
    return {
        type: actionTypes.SET_UPSELL_PRODUCT_ID,
        payload: upsellId,
    } as const;
};

export const setUpsellProduct = (product: UpsellProduct | Record<string, never> | Product) => {
    return {
        type: actionTypes.SET_UPSELL_PRODUCT,
        payload: product,
    } as const;
};

export const setCurrency = (currency: Currency) => {
    return {
        type: actionTypes.SET_PAYMENT_CURRENCY,
        payload: currency,
    } as const;
};

export const changePaymentMethod = (type: string) => {
    return {
        type: actionTypes.CHANGE_PAYMENT_TYPE,
        payload: type,
    } as const;
};

export const setPurchaseWithoutValue = (status: boolean) => {
    return {
        type: actionTypes.SET_PURCHASE_WITHOUT_VALUE,
        payload: status,
    } as const;
};

export const setIntroductoryDiscount = (discount: number) => {
    return {
        type: actionTypes.SET_INTRODUCTORY_DISCOUNT,
        payload: discount,
    } as const;
};

// TODO: remove this logic and saga, unused code, check WHOLE FILE
export const replaceSubscription = (data: IReplaceSubscription) => {
    return {
        type: actionTypes.SET_REPLACE_SUBSCRIPTION,
        payload: data,
    } as const;
};

export const setSubscriptionId = (subscriptionId: string) => {
    return {
        type: actionTypes.SET_SUBSCRIPTION_ID,
        payload: subscriptionId,
    } as const;
};

export const setSandbox = (status: boolean) => {
    return {
        type: actionTypes.SET_SANDBOX,
        payload: status,
    } as const;
};

export const setCurrentCancelSubscriptionId = (id: string) => {
    return {
        type: actionTypes.SET_CURRENT_CANCEL_SUBSCRIPTION_ID,
        payload: id,
    } as const;
};

export const setOpenModal = (modalName: string) => {
    const modalsObjPayload: ModalOpenStatuses = { ...DEFAULT_MODAL_OPEN_STATUS_VALUES };

    for (const key in modalsObjPayload) {
        // @ts-ignore
        key !== modalName ? (modalsObjPayload[key] = false) : (modalsObjPayload[key] = true);
    }

    return {
        type: actionTypes.SET_OPEN_MODAL,
        payload: modalsObjPayload,
    } as const;
};

export const closeModals = () => {
    return {
        type: actionTypes.CLOSE_MODALS,
    } as const;
};

export const setExclusiveOfferHasBeenOpened = (status: boolean) => {
    return {
        type: actionTypes.SET_EXCLUSIVE_OFFER_HAS_BEEN_OPENED,
        payload: status,
    } as const;
};

export const setExclusiveOfferModalStatusClose = () => {
    return {
        type: actionTypes.SET_EXCLUSIVE_OFFER_MODAL_STATUS_CLOSE,
    } as const;
};

export const setPaymentDataAfterValidate = (data: PaymentDataAfterSuccessfulValidate) => {
    return {
        type: actionTypes.VALIDATE_PAYMENT_DATA,
        payload: data,
    } as const;
};

export const setIsPaymentScreen = (isPayment: boolean) => {
    return {
        type: actionTypes.SET_IS_PAYMENT_SCREEN,
        payload: isPayment,
    } as const;
};

export const setCustomPaymentButton = (status: boolean) => {
    return {
        type: actionTypes.SET_CUSTOM_PAYMENT_BUTTON,
        payload: status,
    } as const;
};

export function initSolidPayment(status: boolean) {
    return {
        type: actionTypes.INIT_SOLID_PAYMENT,
        payload: status,
    };
}

export const setIsTimerEndValue = (value: boolean) => {
    return {
        type: actionTypes.SET_IS_TIMER_END,
        payload: value,
    } as const;
};

export const setCurrentProductCode = (code: string) => {
    return {
        type: actionTypes.SET_CURRENT_PRODUCT_CODE,
        payload: code,
    };
};

export const setHideCustomPaymentButton = (status: boolean) => {
    return {
        type: actionTypes.SET_HIDE_CUSTOM_PAYMENT_BUTTON,
        payload: status,
    };
};

export const setIsApplePayAvailable = (isApplePayAvailable: boolean) => {
    return {
        type: actionTypes.SET_IS_APPLE_PAY_AVAILABLE,
        payload: isApplePayAvailable,
    } as const;
};

export const setPaymentErrorCode = (retenoErrorObj: IPaymentFailToReteno) => {
    return {
        type: actionTypes.SET_PAYMENT_RETENO_ERROR,
        payload: retenoErrorObj,
    } as const;
};

export const setPaymentErrorToStore = (retenoErrorObj: IPaymentFailToReteno) => {
    return {
        type: actionTypes.SET_PAYMENT_RETENO_ERROR_STORE,
        payload: retenoErrorObj,
    } as const;
};

export const setIsLoadingBankCard = (isLoading: boolean) => {
    return {
        type: actionTypes.SET_IS_LOADING_BANK_CARD,
        payload: isLoading,
    } as const;
};

export const setIsPaymentSuccess = (status: boolean) => {
    return {
        type: actionTypes.SET_IS_PAYMENT_SUCCESS,
        payload: status,
    } as const;
};

export function setInvoiceInfoReceived(status: boolean) {
    return {
        type: actionTypes.SET_USER_INVOICE_INFO_RECEIVED,
        payload: status,
    } as const;
}
