import { CurrentProduct } from 'types/payments/payments';

import { pageInfo } from 'interfaces/Payments/payments';

interface IUseProducts {
    pageInfo: pageInfo;
}

interface IUseProductsReturn {
    productsListWithDiscount: CurrentProduct[];
    productsListWithoutDiscount: CurrentProduct[];
}

export const setIsPreselectedValue = (list: CurrentProduct[], elementIndex: number) => {
    if (list[elementIndex]) list[elementIndex].is_preselected = true;
};

export const useProducts = ({ pageInfo }: IUseProducts): IUseProductsReturn => {
    const getProductsList = () => {
        const products = pageInfo.products;

        const averageProductValue = products.length / 2;
        const elementIndex = products.findIndex((item) => item.is_preselected);

        const productsListWithDiscount = products.filter((product, i) => i < averageProductValue);
        const productsListWithoutDiscount = products.filter((product, i) => i >= averageProductValue);

        setIsPreselectedValue(productsListWithDiscount, elementIndex);
        setIsPreselectedValue(productsListWithoutDiscount, elementIndex);

        return { productsListWithDiscount, productsListWithoutDiscount };
    };

    return getProductsList();
};
