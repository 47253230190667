import {
    defaultBranchName,
    defaultConsentMarketingStatus,
    defaultCurrentBody,
    defaultCurrentWeight,
    defaultDateOfBirth,
    defaultFitnessLevel,
    defaultGender,
    defaultGoal,
    defaultLanguage,
    defaultMeasureSystem,
    defaultTall,
    defaultTargetBody,
    defaultTargetWeight,
    defaultTargetZones,
    defaultTestName,
    defaultTrainingLocations,
} from './onboarding/defaultOnboardingData';
import { UserSingUpInfo } from '../interfaces/User/user';

export const DEFAULT_USER: UserSingUpInfo = {
    branch_name: defaultBranchName,
    date_of_birth: defaultDateOfBirth,
    email: 'defaultEmail@gmail.com',
    fitness_level: defaultFitnessLevel,
    gender: defaultGender,
    goal: defaultGoal,
    height: defaultTall,
    language: defaultLanguage,
    target_bodytype: defaultTargetBody,
    current_bodytype: defaultCurrentBody,
    problem_zones: defaultTargetZones,
    consent_marketing: defaultConsentMarketingStatus,
    target_weight: defaultTargetWeight,
    test_name: defaultTestName,
    training_locations: defaultTrainingLocations,
    units: defaultMeasureSystem,
    weight: defaultCurrentWeight,
};

export const LTV_COEFFICIENT_KEY = 'ltv_coef';

export const DEFAULT_LTV_COEFFICIENT = 1;

export const DEFAULT_TEST_USER_PASSWORD = '123qwe';
