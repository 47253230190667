export const SET_EMAIL = 'USER/SET_EMAIL';
export const SET_USER_NAME = 'USER/SET_USER_NAME';
export const SET_USER_SURNAME = 'USER/SET_USER_SURNAME';
export const SET_GENDER = 'USER/SET_GENDER';
export const SET_IS_LOGIN = 'USER/SET_IS_LOGIN';
export const SET_USER_ID = 'USER/SET_USER_ID';
export const SET_USER_TOKEN = 'USER/SET_USER_TOKEN';
export const SET_PAYMENT_TYPE = 'USER/SET_PAYMENT_TYPE';
export const SET_LANGUAGE = 'USER/SET_LANGUAGE';
export const SET_PAID_STATUS = 'USER/SET_PAID_STATUS';
export const UPDATE_INFO_OLD = 'USER/UPDATE_INFO_OLD';
export const UPDATE_INFO_SUCCESS = 'USER/UPDATE_INFO_SUCCESS';
export const UPDATE_INFO_FAIL = 'USER/UPDATE_INFO_FAIL';
export const SIGN_UP = 'USER/SIGN_UP';
export const SIGN_UP_SUCCESS = 'USER/SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'USER/SIGN_UP_FAIL';
export const SET_IS_AUTOLOGIN_COMPLETE = 'USER/SET_IS_AUTOLOGIN_COMPLETE';
export const SET_COUNTRY = 'USER/SET_COUNTRY';
export const SIGN_IN = 'USER/SIGN_IN';
export const SHOULD_USER_MOVE_TO_PAGE = 'USER/SHOULD_USER_MOVE_TO_PAGE';
export const SET_PURCHASED_PRODUCT_LIST = 'USER/SET_PURCHASED_PRODUCT_LIST';
export const SET_DEFAULT_AMAZON_ANALYTIC_DATA = 'USER/SET_DEFAULT_AMAZON_ANALYTIC_DATA';
export const SET_FULL_URL = 'SET_FULL_URL';
export const SET_IS_INIT_TESTANIA = 'USER/SET_IS_INIT_TESTANIA';
export const SET_USER_ERROR_INFO = 'USER/SET_USER_ERROR_INFO';
export const SET_BRANCH_FOR_CHALLENGE = 'TESTANIA/SET_BRANCH_FOR_CHALLENGE';
export const HANDLE_ERROR_ACTION = 'ERROR/HANDLE_ERROR';
