import { isLocalHost, removeLocalhostFromObject } from 'helpers/utils';
import { fromPennyToInt } from 'helpers/payment/paymentUtils';
import { calculateLTV } from 'helpers/payment/PaymentFlow/utils';
import { cleanObject } from 'helpers/other/dataStructuresUtils';

import { currency, DataAfterNormalization, OrderType, RawData } from 'interfaces/Payments/payments';

const normalizeData = ({
    currentProduct,
    currency,
    userId,
    testaniaName,
    fullURL,
    paymentMethodName,
    defaultAmazonPurchaseData,
}: RawData): DataAfterNormalization => {
    const data = {
        currency: currency?.name as currency,
        value: calculateLTV(currentProduct?.ltv, currentProduct?.period),
        user_id: userId,
        tariff: currentProduct?.id as string, // TODO: check is it ok?
        ab_test_name: testaniaName,
        trial: !!currentProduct?.trial,
        payment_type: currentProduct?.payment_type as OrderType,
        payment_method_name: paymentMethodName,
        payment_method: currentProduct?.payment_method as number,
        flow_link: fullURL,
        product_code: currentProduct?.product_code as string,
        subscription_period: currentProduct?.period as number,
        subscription_trial_period: currentProduct?.trial as number,
        subscription_price: fromPennyToInt(currentProduct?.start_price),
        introductorySubscriptionPrice: currentProduct?.start_price as number,
        defaultAmazonPurchaseData,
    };

    return cleanObject(isLocalHost() ? removeLocalhostFromObject(data) : data);
};

export default normalizeData;
