/* eslint-disable max-lines */
import * as action from './actions';

import * as actionTypes from './actionTypes';

import {
    defaultFitnessLevel,
    defaultGoal,
    defaultPreferActivity,
    defaultCurrentBody,
    defaultTargetBody,
    defaultTargetZones,
    defaultTall,
    defaultCurrentWeight,
    defaultTargetWeight,
    defaultAge,
    defaultConsentMarketingStatus,
    defaultSedentaryLifeStyle,
    defaultActivityLvl,
    defaultBranchName,
    defaultTestName,
    defaultMeasureSystem,
} from 'constants/onboarding/defaultOnboardingData';

import { getKgWeight, getNumberSystem } from 'helpers/onboarding/onboardingUtils';

import { InferValueTypes } from 'types/commonInterfaces';

import { OnBoardingState } from 'interfaces/Onboarding/onboarding';

const initialState: OnBoardingState = {
    config: null,
    goal: defaultGoal,
    goal_wide: null,
    current_body: defaultCurrentBody,
    current_body_type_a: defaultCurrentBody,
    target_body: defaultTargetBody,
    target_zone: defaultTargetZones,
    fitness_lvl: defaultFitnessLevel,
    age: defaultAge,
    tall: defaultTall,
    currentWeight: defaultCurrentWeight,
    targetWeight: defaultTargetWeight,
    numberSystem: null,
    consent_marketing: defaultConsentMarketingStatus,
    preferActivity: defaultPreferActivity,
    measureSystem: defaultMeasureSystem,
    training_locations: null,
    nextStep: '/',
    urlParams: `{"branch-name":"${defaultBranchName}","test-name":"${defaultTestName}"}`,
    sedentaryLifeStyle: defaultSedentaryLifeStyle,
    activityLvl: defaultActivityLvl,
    occasion: null,
    ageGroup: null,
    sport: null,
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const OnboardingReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SET_CURRENT_BODY:
            return {
                ...state,
                current_body: action.payload,
            };

        case actionTypes.SET_CURRENT_BODY_TYPE_A:
            return {
                ...state,
                current_body_type_a: action.payload,
            };

        case actionTypes.SET_GOAL:
            return {
                ...state,
                goal: action.payload,
            };

        case actionTypes.SET_GOAL_WIDE:
            return {
                ...state,
                goal_wide: action.payload,
            };

        case actionTypes.SET_TARGET_BODY:
            return {
                ...state,
                target_body: action.payload,
            };

        case actionTypes.SET_TARGET_ZONE: {
            return {
                ...state,
                target_zone: action.payload,
            };
        }

        case actionTypes.SET_CURRENT_WEIGHT: {
            const currentWeight: number = getKgWeight(action.payload);
            const numberSystem: string = getNumberSystem(action.payload.unit);

            return {
                ...state,
                currentWeight,
                numberSystem,
            };
        }

        case actionTypes.SET_CONFIG: {
            return {
                ...state,
                config: action.payload,
            };
        }

        case actionTypes.SET_TARGET_WEIGHT: {
            const targetWeight: number = getKgWeight(action.payload);
            const numberSystem: string = getNumberSystem(action.payload.unit);

            return {
                ...state,
                targetWeight,
                numberSystem,
            };
        }

        case actionTypes.SET_ACTIVITY_LVL:
            return {
                ...state,
                activityLvl: action.payload,
            };

        case actionTypes.SET_FITNESS_LVL:
            return {
                ...state,
                fitness_lvl: action.payload,
            };

        case actionTypes.SET_AGE: {
            return {
                ...state,
                age: action.payload,
            };
        }

        case actionTypes.SET_TALL: {
            const tall: number = action.payload.value;
            const numberSystem: string = getNumberSystem(action.payload.unit);

            return {
                ...state,
                tall,
                numberSystem,
            };
        }

        case actionTypes.SET_PULSE_MAILING_ACTIVE:
            return {
                ...state,
                consent_marketing: action.payload,
            };

        case actionTypes.SET_MEASURE_SYSTEM:
            return {
                ...state,
                measureSystem: action.payload,
            };

        case actionTypes.SET_NEXT_STEP: {
            return { ...state, nextStep: action.payload };
        }

        case actionTypes.SET_TRAINING_LOCATION: {
            return {
                ...state,
                training_locations: action.payload,
            };
        }

        case actionTypes.SET_URL_PARAMS: {
            const stringifyData = JSON.stringify(action.payload);

            localStorage.setItem('urlParams', stringifyData);

            return {
                ...state,
                urlParams: action.payload,
            };
        }

        case actionTypes.SET_LIFESTYLE: {
            return {
                ...state,
                sedentaryLifeStyle: action.payload,
            };
        }

        case actionTypes.SET_OCCASION: {
            return {
                ...state,
                occasion: action.payload,
            };
        }

        case actionTypes.SET_AGE_GROUP: {
            return {
                ...state,
                ageGroup: action.payload,
            };
        }

        case actionTypes.SET_SPORT: {
            return {
                ...state,
                sport: action.payload,
            };
        }

        default:
            return state;
    }
};

export default OnboardingReducer;
