import { useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';

// import { selectHidePayPal } from 'redux/UiEffects/selectors';
import { Product } from 'redux/sagas/user/interfaces';
import { selectCurrentProduct } from 'redux/Payment/selectors';

interface IUseSolidPaymentPreInit {
    initPayPal: () => void;
    initPaymentGenerator: () => void;
}

export function useSolidPaymentPreInit({ initPayPal, initPaymentGenerator }: IUseSolidPaymentPreInit) {
    const currentProduct = useSelector(selectCurrentProduct);
    const isHidePayPal = true;

    const currentProductPrev = useRef<Product | null>(null);

    useEffect(() => {
        // @ts-ignore
        const isNotTheSameProduct = currentProduct?.id !== currentProductPrev.current?.id;
        const ppButton = document.getElementById('paypal-button');

        if ((ppButton && isNotTheSameProduct) || isHidePayPal) {
            if (!isHidePayPal) {
                initPayPal();
            }

            initPaymentGenerator();

            // @ts-ignore
            currentProductPrev.current = currentProduct;
        }
    }, [currentProduct]);
}
