import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { call, delay, spawn, all } from 'redux-saga/effects';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import { updateUserMailingStatus } from './user/updateUserSendPulseStatus';
import { setToast } from './user/toastMessages';
import { handleThankYouSubmit } from './user/thankyou';
import { skipOnBoardingSaga } from './user/skipOnboarding';
import { signUpUser } from './user/signup';
import { setUserIdSaga } from './user/setUserId';
import { setDefaultMeasure } from './user/measure';
import { frontChat } from './user/frontChat';
import { watchHandleErrorAction } from './user/errorsHandling';
import { setDefaultCurrencySaga } from './user/currency';
import { setUserProfile } from './user/autologin';
import { validatePayment } from './payment/validatePayment';
import { setPaymentErrorCodeToReteno } from './payment/sendPaymentFailToReteno';
import { replaceSubscriptionSaga } from './payment/replaceSubscription';
import { initOneClickPaymentSaga } from './payment/oneClickPayment';
import { initSolidPayment } from './payment/initSolid';
import { initPayPalSaga } from './payment/initPayPal';
import { initPaymentGenerator } from './payment/initPaymentGenerator';

export default function* rootSaga() {
    const sagasList = [
        initSolidPayment,
        initPayPalSaga,
        validatePayment,
        setDefaultCurrencySaga,
        initOneClickPaymentSaga,
        setUserProfile,
        signUpUser,
        frontChat,
        setToast,
        replaceSubscriptionSaga,
        skipOnBoardingSaga,
        handleThankYouSubmit,
        updateUserMailingStatus,
        setUserIdSaga,
        setDefaultMeasure,
        initPaymentGenerator,
        setPaymentErrorCodeToReteno,
        watchHandleErrorAction,
    ];

    // @ts-ignore
    const retrySagas = yield sagasList.map((saga) => {
        return spawn(function* () {
            let counter;

            for (counter = 0; counter < 5; counter++) {
                try {
                    // @ts-ignore
                    yield call(saga);
                    break;
                } catch (e) {
                    yield delay(1000);
                    if (counter === 4) {
                        sentryCaptureEvent(e, SENTRY_APP, ERROR_LEVELS.ERROR);
                    }
                }
            }
        });
    });

    yield all(retrySagas);
}
