import { Init } from 'testania';

import config from 'config';

import { defaultBranchName, defaultTestName } from 'constants/onboarding/defaultOnboardingData';
import { DEFAULT_FLOW } from 'constants/general/defaultFlow';

import { checkFbBots } from 'helpers/other/commonUtils';
import { getBranchNameFromLocalStorage, getNameFromUrl } from './helpers';

const URL_PARAMS_NAMES = {
    test: 'test-name',
    branch: 'branch-name',
    variant: 'variant-name',
};

export const TESTANIA_INIT_CONFIG: Init = {
    testName: getNameFromUrl(URL_PARAMS_NAMES.test, defaultTestName),
    branchName: getNameFromUrl(URL_PARAMS_NAMES.branch, getBranchNameFromLocalStorage() || defaultBranchName),
    variantName: getNameFromUrl(URL_PARAMS_NAMES.variant, ''),
    defaultBranches: ['fasting', 'default'],
    defaultFlow: DEFAULT_FLOW,
    testaniaUrl: config.TESTANIA_HOST,
    testaniaApiKey: config.TESTANIA_X_API_KEY,
    project: 'menscoach',
    expireConfigTime: 1440,
    runOnlyLocalConfig: checkFbBots(),
};

export const THANK_YOU_PAGE_PATH = 'thank-you';

export const DOWNLOAD_APP_PATH = 'muscle-booster-app';

export const URL_PARAMS_FOR_SKIP_THANK_YOU_PAGE = {
    branchName: 'general',
    testName: 'remove_thank_you_page',
};
