import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { takeEvery } from 'redux-saga/effects';

import { HANDLE_ERROR_ACTION } from 'redux/User/actionTypes';
import { handleErrorAction } from 'redux/User/actions';

import sentry from 'services/Sentry/SentryInstance';

import { isApiClientError } from 'welltech-web-api-client';

function* handleErrorSaga({ payload: { error, data, logType, customTags } }: ReturnType<typeof handleErrorAction>) {
    if (isApiClientError(error)) {
        return;
    }

    console.error('Error:', error);

    sentry.logError(
        error,
        logType,
        logType === SENTRY_PAYMENT ? ERROR_LEVELS.CRITICAL : ERROR_LEVELS.ERROR,
        {
            ...data,
        },
        customTags
    );
}

export const watchHandleErrorAction = function* () {
    yield takeEvery(HANDLE_ERROR_ACTION, handleErrorSaga);
};
