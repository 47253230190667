/* eslint-disable quotes */
export const MUSCLE_GAIN = 'muscle_gain';
export const WEIGHT_LOSS = 'weight_loss';

export enum zones {
    arms = 'arms',
    legs = 'legs',
    pecs = 'pecs',
    belly = 'belly',
    buttocks = 'buttocks',
}

export enum locations {
    home = 'home',
    gym = 'gym',
}

export enum BMIValues {
    bmiLow = 25,
    bmiNormal = 28,
    bmiOver = 31,
}

export const weeksInMonth = 4;

export const SKINNY = 'skinny';
export const AVERAGE = 'average';
export const OVERWEIGHT = 'overweight';
export const SLIGHTLY_OVERWEIGHT = 'slightly_overweight';
export const EXTRA = 'extra';
export const CUT = 'cut';
export const FIT = 'cut';
export const BULK = 'bulk';
export const EXTRA_BULK = 'extra_bulk';

export const NONE_OF_THE_ABOVE_VALUE = 'none_of_the_above';
export const NONE_OF_THIS = 'none_of_this';
export const NONE = 'none';
export const CALISTHENICS_BRANCH = 'calisthenics';
