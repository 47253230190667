/* eslint-disable max-lines */
import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectUser } from 'redux/User/selectors';
import { handleErrorAction } from 'redux/User/actions';
import { selectIsLoading, selectPayment, selectValidatePaymentData } from 'redux/Payment/selectors';
import {
    initPaymentGenerator as initPaymentGeneratorAction,
    initPayPal as initPayPalAction,
    setIsLoadingBankCard,
    setIsPaymentScreen,
    setLoadingStatus,
} from 'redux/Payment/actions';

import { PAYMENT_TYPES, SOLID_ERROR_CODES } from 'constants/payment/payments';

import useValidatePayment from 'hooks/useValidatePayment';
import useCurrencyData from 'hooks/useCurrencyData';

import { IUsePaymentLibData } from 'types/payments/payments';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import { DataForValidate } from 'interfaces/Payments/paymentSystemsInit';
import { pageInfo } from 'interfaces/Payments/payments';
import { useSolidPaymentPreInit } from './useSolidPaymentPreInit';

interface IUsePaymentLib {
    pageInfo: pageInfo;
    toNextPage: ({ isValid }: { isValid: boolean }) => void;
}

const PAYPAL_ERROR = 'PayPal error';

export const usePaymentLib = ({ toNextPage, pageInfo }: IUsePaymentLib): IUsePaymentLibData => {
    const dispatch = useDispatch();

    const isLoading = useSelector(selectIsLoading);
    const validatePaymentData = useSelector(selectValidatePaymentData);
    const { currentProduct } = useSelector(selectPayment);
    const { user_id, country } = useSelector(selectUser);

    const paymentType = pageInfo.products[0].payment_type;

    const { currencyName } = useCurrencyData();
    const validatePayment = useValidatePayment({ pageInfo });

    useSolidPaymentPreInit({ initPayPal, initPaymentGenerator });

    useEffect(() => {
        dispatch(setIsPaymentScreen(true));

        return () => {
            dispatch(setIsPaymentScreen(false));
        };
    }, []);

    useEffect(() => {
        // @ts-ignore
        if (validatePaymentData && Boolean(validatePaymentData?.result)) {
            toNextPage({
                // @ts-ignore
                isValid: validatePaymentData.result,
            });
        }
    }, [validatePaymentData]);

    function initPayPal() {
        const meta = {
            userId: user_id,
            payment_method: PAYMENT_TYPES.PAYPAL,
            currency: currencyName,
            product_code: currentProduct?.product_code,
            country,
        };

        const successHandler = (data: DataForValidate) => {
            const errorCode = data?.error?.code;

            if (!errorCode) {
                validatePayment('payPal', meta.payment_method, {
                    order: {
                        amount: data.order.amount as number,
                        order_id: data.order.order_id as string,
                        subscription_id: data.order.subscription_id as string,
                    },
                });

                return;
            }

            dispatch(setLoadingStatus(false));
            initPayPal();

            dispatch(
                handleErrorAction(
                    new Error(PAYPAL_ERROR),
                    SENTRY_PAYMENT,
                    {
                        ...currentProduct,
                        ...meta,
                    },
                    [
                        ['SOLID_ERROR_CODE', errorCode],
                        ['SOLID_ERROR_MESSAGE', data?.error?.recommended_message_for_user || ''],
                        ['SOLID_METHOD', data?.order?.method],
                        ['reason', getErrorReasonGroup(errorCode)],
                    ]
                )
            );
        };

        const onClickHandler = () => {
            dispatch(setLoadingStatus(true));

            Main.customData('processor_form__click', { payment: 'paypal', event_label: 'paypal' });
        };

        const errorHandler = (e: Error) => {
            sentryCaptureEvent(e, SENTRY_PAYMENT, ERROR_LEVELS.ERROR, {
                // @ts-ignore
                ...currentProduct,
                ...meta,
            });

            dispatch(setLoadingStatus(false));
        };

        const processingHandler = () => {
            dispatch(setLoadingStatus(true));
        };

        const readyHandler = () => {
            dispatch(setLoadingStatus(false));

            Main.customData('processor_form__load', { payment: 'paypal', event_label: 'paypal' });
        };

        // @ts-ignore
        dispatch(
            initPayPalAction({
                // @ts-ignore
                ...currentProduct,
                ...meta,
                // @ts-ignore
                successHandler,
                onClickHandler,
                errorHandler,
                processingHandler,
                readyHandler,
            })
        );
    }

    const getErrorReasonGroup = (errorCode: string) => {
        let reason = 'SOLID_NOT_STANDARD_ERROR';

        SOLID_ERROR_CODES.map(({ name, errorList }) => {
            if (errorList.includes(errorCode)) {
                reason = name;
            }
        });

        return reason;
    };

    function initPaymentGenerator() {
        const meta = {
            userId: user_id as number,
            payment_method: PAYMENT_TYPES.BANK_CARD,
            product_code: currentProduct?.product_code as string,
            country,
            currency: currencyName,
        };

        dispatch(setIsLoadingBankCard(true));

        dispatch(
            initPaymentGeneratorAction({
                // @ts-ignore
                ...currentProduct,
                ...meta,
            })
        );
    }

    return {
        paymentType,
        isLoading: Boolean(isLoading),
        payment: {
            initPayPal,
            initPaymentGenerator,
        },
    };
};
